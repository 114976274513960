import React, { useState, useEffect } from "react";
import { ref, getDownloadURL } from 'firebase/storage';
// import { storage } from "../../fireBase";
import "./Logo.css";
const Logo = () => {
  const [imageURL, setImageURL] = useState(null);

  // const getFirebaseImageURL = async (imageName) => {
  //   try {
  //     const url = await storage.ref(`images/${imageName}`).getDownloadURL();
  //     return url;
  //   } catch (error) {
  //     console.error("Erro ao obter URL da imagem do Firebase:", error);
  //     return null;
  //   }
  // };

  

  // useEffect(() => {
  //   const imageName = "logo.png";
    
  //   getFirebaseImageURL(imageName).then((url) => {
  //     setImageURL(url);
  //   });
  // }, []);

  /*
  const storageRF = ref(storage, `images/${file.name}`);

  getDownloadURL(imageName).then((url) => {
    setImageURL(url);
  });
  */

  return (
    <img
      id="logo"
      // src={logoApp}
      src="https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Flogo.png?alt=media&token=9e70aaae-3cef-454d-8a93-49b7ae7f0eae&_gl=1*14porip*_ga*NjMzNTUwOTI5LjE2OTU4NzAwNDc.*_ga_CW55HF8NVT*MTY5NTg3MDA0Ny4xLjEuMTY5NTg3Mzg1MC40NS4wLjA."
      alt="Educaflix Logo"
    />
  );
};

export default Logo;
