import React, { useState, useEffect } from 'react';
import { useAuth } from "../../providers/auth";
import { useHistory, useLocation } from "react-router-dom";
import { ReactNetflixPlayer } from "react-netflix-player";
import axios from 'axios';
import './Player.css';

const api_url = process.env.REACT_APP_API_URL;

const Player = ({ videoId }) => {
  const { user } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const [dados, setDados] = useState(null);
  const id = location.pathname.split('/').pop(); 
  const urlVideo = location.state.urlvideo; 
  const title = location.state.title; 
  const titleMedia = location.state.titleMedia; 

  const isAuthenticated = !!user.token;

  if (!isAuthenticated) {
    history.push("/login");
  }

  // useEffect(() => {
  //   const carregarDados = async () => {
  //     try {
  //       const response = await axios.get(`${api_url}/lesson_details?lesson_id=${videoId}&auth_token=${user.token}`);
  //       console.log('Dados do video', response.data);
  //       setDados(response.data);
  //     } catch (error) {
  //       console.error('Erro ao carregar os dados:', error);
  //     }
  //   };

  //   carregarDados();
  // }, []);

  if (urlVideo === null) {
    return <div>Carregando...</div>;
  }

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className="video-player-container">
      <ReactNetflixPlayer
        src={urlVideo}
        title={title}
        subTitle=""
        primaryColor='#6c12d3'
        secundaryColor='#8a46d8'
        // titleMedia={`Tempo do conteúdo: ${titleMedia}`}
        extraInfoMedia=""
        fullPlayer={true}
        backButton={handleGoBack}
        autoPlay={true}
      />
    </div>
  );
};

export default Player;
