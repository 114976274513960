import React, { useState } from "react";
import { apiKey } from "../../utils";
import axios from "axios";
import useSearch from "../../hooks/useSearch";
import { useHistory } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import Swal from 'sweetalert2';
import "./LoginPage.css";
import { useAuth } from "../../providers/auth";

const api_url = process.env.REACT_APP_API_URL;

const Login = () => {
  const { user, setUser } = useAuth();
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isloading, setIsloading] = useState(null);

  const login = async (email, password) => {
    try {
      let param = {
        email,
        password
    }
      const response = await axios.post(
        `${api_url}/login`, param
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      } else {
        return { success: false, message: `Erro: ${response.statusText}` };
      }
    } catch (error) {
      return {
        success: false,
        message: "Erro ao fazer login. Verifique suas credenciais.",
      };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsloading(true);
    try {
      const response = await login(username, password);
      if (response.success) {
        if (response.data.validity) {
          setUser({
            user_id: response.data.user_id,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            email: response.data.email,
            cep: response.data.cep,
            endereco: response.data.endereco,
            telefone: response.data.telefone,
            role: response.data.role,
            validity: response.data.validity,
            token: response.data.token,
            image: response.data.image,
            auth_token: response.data.token,
            datanascimento: response.data.datanascimento,
            sexo: response.data.sexo,
          });
          setIsloading(false);
          history.push("/");
        } else {
          setIsloading(false);
          Swal.fire({
            title: 'Login',
            text: 'Falha no login, favor corrigir as credenciais.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Fechar',
            customClass: {
              popup: 'dark-mode', // Adiciona a classe 'dark-mode' para o modo escuro
            },
          });
        }
        // console.log('Login bem-sucedido:', response.data);
      } else {
        setIsloading(false);
        Swal.fire({
          title: 'Login',
          text: 'Falha no login, favor corrigir as credenciais.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Fechar',
          customClass: {
            popup: 'dark-mode', // Adiciona a classe 'dark-mode' para o modo escuro
          },
        });
      }
    } catch (error) {
      setIsloading(false);
      Swal.fire({
        title: 'Login',
        text: `Erro ao fazer login: ${error}`,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Fechar',
        customClass: {
          popup: 'dark-mode', // Adiciona a classe 'dark-mode' para o modo escuro
        },
      });
    }
  };

  const handleAssinatur = () => {
    history.push("/assinatura");
  };

  const handleRecovery = () => {
    history.push("/recuperarsenha");
  };

  return (
    <main className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h3 class="text-input-title-form">Use o seu e-mail para entrar</h3>
        <div class="space-text">
          <span>
            <p class="text-color--primary" style={{ display: "inline" }}>
              Você usará esse e-mail e senha para entrar na conta do EducaFlix e
              assistir suas aulas e cursos preferidos.
            </p>
          </span>
        </div>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="E-mail"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Senha"
          required
        />
        <div className="space-text-recovery">
          <p class="text-color--primary" style={{ display: "inline-block" }}>
            Esqueceu a senha?
          </p>
          <button
            type="button"
            aria-label="Assine"
            className="link-assine text-color--primary"
            style={{ display: "inline-block" }}
            onClick={handleRecovery}
          >
            <b>Clique aqui</b>
          </button>
        </div>
        <button className="button-login" type="submit">
          {isloading ? (
            <SpinnerCircular
              color="rgba(255, 255, 255, 1)"
              secondaryColor="rgba(0, 0, 0, 0.44)"
              size={20}
            />
          ) : (
            "ENTRAR"
          )}
        </button>
        <div className="space-text-assine">
          <p class="text-color--primary" style={{ display: "inline-block" }}>
            Não tem EducaFlix?
          </p>
          <button
            type="button"
            aria-label="Assine"
            className="link-assine text-color--primary"
            style={{ display: "inline-block" }}
            onClick={handleAssinatur}
          >
            <b>Assine</b>
          </button>
        </div>
        
      </form>
    </main>
  );
};

export default Login;
