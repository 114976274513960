import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <footer>
      <p>
      © 2023 EDUCAFLIX
      Produto comercializado por EDUCA TECNOLOGIA LTDA.
      Endereço: Rua Governador José Malcher, 153, sala 12, Bairro de Nazaré, Belém, Pará – CEP 66035-065, Brasil
      CNPJ: 26.549.015/0001-05
      Todos os direitos reservados, todas as imagens, conteúdos, vídeos e programas nesta plataforma são marcas 
      registradas da EDUCA TECNOLOGIA LTDA, e qualquer duplicação e/ou cópia são estritamente proibidas.
      </p>
      <img src="https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Flogo.png?alt=media&token=9e70aaae-3cef-454d-8a93-49b7ae7f0eae&_gl=1*14porip*_ga*NjMzNTUwOTI5LjE2OTU4NzAwNDc.*_ga_CW55HF8NVT*MTY5NTg3MDA0Ny4xLjEuMTY5NTg3Mzg1MC40NS4wLjA." alt="EducaFlix"/>
    </footer>
  );
};

export default Footer;
