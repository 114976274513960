import React from "react";
import { NotificationsProvider } from "reapop";
import { useAuth } from "./providers/auth";
import Header from "./components/Header";
import Home from "./components/Home";
import Signature from "./components/Signature";
import DetailPage from "./components/DetailPage";
import CourseDetailPage from "./components/CourseDetailPage";
import LoginPage from "./components/LoginPage";
import RecoveryPassword from "./components/RecoveryPassword";
import ChangePassword from "./components/ChangePassword";
import ProfilePage from "./components/ProfilePage";
import PdfPage from "./components/PdfPage";
import AtividadePage from "./components/AtividadePage";
import BrandPage from "./components/BrandPage";
import SearchPage from "./components/SearchPage";
import VideoAula from "./components/Player";
import Footer from "./components/Footer";
import {
  // BrowserRouter as Router,
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./App.css";

const PrivateRoute = ({ children }) => {
  const { user } = useAuth();
  const isAuthenticated = !!user.token;

  return isAuthenticated ? children : <Redirect to="/login" />;
};

const App = () => {
  return (
    <>
      <NotificationsProvider>
        <Router>
          <Switch>
            <Route path="/brand/:brand">
              <Header className="headerTransparent" />
              <BrandPage />
              <Footer />
            </Route>
            <Route path="/play/:movieId">
              <Header className="headerTransparent" />
              <DetailPage />
              <Footer />
            </Route>
            <Route path="/busca">
              <Header />
              <SearchPage />
              <Footer />
            </Route>
            <Route path="/login">
              <Header />
              <LoginPage />
              <Footer />
            </Route>
            <Route path="/recuperarsenha">
              <Header />
              <RecoveryPassword />
              <Footer />
            </Route>
            <Route path="/trocarsenha">
              <Header />
              <ChangePassword />
              <Footer />
            </Route>
            <Route path="/assinatura">
              <Header />
              <Signature />
              <Footer />
            </Route>
            <Route path="/perfil">
              <Header />
              <ProfilePage />
              <Footer />
            </Route>
            <Route path="/teoria">
              <Header />
              <PdfPage />
              <Footer />
            </Route>
            <Route path="/atividade">
              <Header />
              <AtividadePage />
              <Footer />
            </Route>
            <Route path="/aula">
              <Header />
              <VideoAula />
              <Footer />
            </Route>
            <Route path="/detalhescurso">
              <Header />
              <CourseDetailPage />
              <Footer />
            </Route>
            <Route path="/">
              <Header />
              <Home />
              <Footer />
            </Route>
          </Switch>
        </Router>
      </NotificationsProvider>
    </>
  );
};

export default App;
