import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
export const apiKey = process.env.REACT_APP_API_KEY;
export const sliderConfig = {
  arrows: true,
  accessibility: true,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  nextArrow: (
    <button className="arrow-button">
      <ArrowForwardIosIcon className="slick-arrows" />
    </button>
  ),
  prevArrow: (
    <button className="arrow-button">
      <ArrowBackIosIcon className="slick-arrows" />
    </button>
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};
export const imageSliderConfig = {
  dots: true,
  accessibility: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 6000,
  speed: 700,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: "100px",
  responsive: [
    {
      breakpoint: 992,
    },
    {
      breakpoint: 10000,
      centerMode: true,
    },
  ],
};
export const sliderImages = [
  {
    id: "1",
    parceiro: "faspm",
    textImage:
      "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fbanners%2Fbannerfaspmopacity.png?alt=media&token=c1f69438-b319-496b-8f4e-6c0edad0b956&_gl=1*1kwtpq6*_ga*ODIyOTEzNTM5LjE2OTU5NDA2Mzc.*_ga_CW55HF8NVT*MTY5NTk0MDYzNy4xLjEuMTY5NTk0MTk1OC42MC4wLjA.",
    url:
      "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fbanners%2Fbannerfaspm.jpg?alt=media&token=730804de-f7a1-4a2c-9848-8655a37b8712&_gl=1*yw0cci*_ga*ODIyOTEzNTM5LjE2OTU5NDA2Mzc.*_ga_CW55HF8NVT*MTY5NTk0MDYzNy4xLjEuMTY5NTk0MTgzMi4yMC4wLjA.",
  },
  {
    id: "2",
    parceiro: "educaflix",
    textImage:
      "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fslides%2Fbannerhomeeducaflix.png?alt=media&token=754690c8-58ce-4d62-bc9a-a91e5cd9f835&_gl=1*18mj2n8*_ga*ODIyOTEzNTM5LjE2OTU5NDA2Mzc.*_ga_CW55HF8NVT*MTY5NTk0ODk3My4yLjEuMTY5NTk0OTExNi4yNy4wLjA.",
    url:
      "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fslides%2Fbannereducaflixenem.jpg?alt=media&token=5c902987-ae9e-49a3-975e-6a480ac93df5&_gl=1*16l5vg1*_ga*ODIyOTEzNTM5LjE2OTU5NDA2Mzc.*_ga_CW55HF8NVT*MTY5NTk0ODk3My4yLjEuMTY5NTk0OTA4My42MC4wLjA.",
  },
  {
    id: "3",
    parceiro: "ingrid",
    textImage:
      "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fslides%2Fbannerhomeingrid.png?alt=media&token=b97a5090-7658-4daa-8c6c-8edc2a260aca&_gl=1*u7oarg*_ga*ODIyOTEzNTM5LjE2OTU5NDA2Mzc.*_ga_CW55HF8NVT*MTY5NTk0ODk3My4yLjEuMTY5NTk0OTE4OS42MC4wLjA.",
    url:
      "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fslides%2Fbanneringridconcursos.jpg?alt=media&token=db411f01-9c22-4936-885f-dec54f7592ca&_gl=1*1eshpp3*_ga*ODIyOTEzNTM5LjE2OTU5NDA2Mzc.*_ga_CW55HF8NVT*MTY5NTk0ODk3My4yLjEuMTY5NTk0OTIzMi4xNy4wLjA.",
  },
  {
    id: "4",
    parceiro: "",
    textImage:
      "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fslides%2Fbannerhomemfv.png?alt=media&token=1ca15d2e-a633-4d65-b566-bd9e50b650f7&_gl=1*1m5b2n*_ga*ODIyOTEzNTM5LjE2OTU5NDA2Mzc.*_ga_CW55HF8NVT*MTY5NTk0ODk3My4yLjEuMTY5NTk0OTI3Ni41Mi4wLjA.",
    url:
      "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fslides%2Fbannermfv.jpg?alt=media&token=92cada69-f21d-4b3f-9fb4-fede8c697cc1&_gl=1*wme2g1*_ga*ODIyOTEzNTM5LjE2OTU5NDA2Mzc.*_ga_CW55HF8NVT*MTY5NTk0ODk3My4yLjEuMTY5NTk0OTI2OC42MC4wLjA.",
  },
  {
    id: "5",
    parceiro: "ondrone",
    textImage:
      "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fslides%2Fbannerhomeondrone.png?alt=media&token=04cb27cd-eb67-4a1b-80af-0e87881c1ec5&_gl=1*efusxs*_ga*ODIyOTEzNTM5LjE2OTU5NDA2Mzc.*_ga_CW55HF8NVT*MTY5NTk0ODk3My4yLjEuMTY5NTk0OTMxNi4xMi4wLjA.",
    url:
      "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fslides%2Fbannerondrone.jpg?alt=media&token=54576585-da82-41e1-8588-07bc2e7653d3&_gl=1*1p62nnq*_ga*ODIyOTEzNTM5LjE2OTU5NDA2Mzc.*_ga_CW55HF8NVT*MTY5NTk0ODk3My4yLjEuMTY5NTk0OTMyMC44LjAuMA..",
  },
];

export const termos = [
  {
    tipo: "contrato",
    text:`
    CONTRATO DE ASSINATURA DA EMPRESA EDUCA TECNOLOGIA LTDA
    Este Contrato de Assinatura ("Contrato") é celebrado entre a Empresa Educa Tecnologia LTDA, com sede na Rua Governador José Malcher, 153, sala 12, Bairro de Nazaré, Belém, Pará, CEP 66035-065, Brasil, CNPJ: 26.549.015/0001-05, doravante referida como "Educa Tecnologia", e o Usuário, doravante referido como "Assinante".
    
    1. OBJETO DO CONTRATO
    1.1. O objeto deste Contrato é permitir que o Assinante acesse e assine cursos oferecidos pela Educa Tecnologia através da Plataforma EducaFlix, com acesso em múltiplas telas, incluindo smartphones, web e Smart TVs, e acesso ilimitado 24/7 durante o período de um ano.
    
    2. ASSINATURA
    2.1. O Assinante concorda em assinar cursos específicos na Plataforma EducaFlix, selecionando as opções desejadas a partir das ofertas disponíveis.
    2.2. A assinatura dos cursos terá a duração de um ano, a partir da data de ativação da assinatura.
    
    3. ACESSO E USO DA PLATAFORMA
    3.1. O Assinante terá o direito de acessar e utilizar os cursos assinados durante o período de um ano, a partir da ativação da assinatura.
    3.2. O acesso à Plataforma EducaFlix será permitido em dispositivos móveis, web e Smart TVs, de acordo com as opções disponíveis na Plataforma.
    3.3. O Assinante terá acesso ilimitado aos cursos escolhidos, 24 horas por dia, 7 dias por semana, durante o período de um ano.
    
    4. PAGAMENTO
    4.1. O Assinante concorda em pagar a taxa de assinatura correspondente aos cursos selecionados, de acordo com as tarifas e termos especificados na Plataforma EducaFlix.
    4.2. A Educa Tecnologia reserva-se o direito de alterar as tarifas de assinatura a qualquer momento, com aviso prévio adequado aos Assinantes.
    
    5. CANCELAMENTO
    5.1. O Assinante pode cancelar a assinatura dos cursos a qualquer momento, mas após 72 horas o Assinante não terá direito a reembolso das taxas de assinatura já pagas.
    
    6. PROPRIEDADE INTELECTUAL
    6.1. Todo o conteúdo dos cursos disponíveis na Plataforma EducaFlix é de propriedade exclusiva da Educa Tecnologia ou de seus licenciadores e está protegido pelas leis de direitos autorais e outras leis de propriedade intelectual.
    6.2. O Assinante concorda em não copiar, distribuir, transmitir, exibir, vender, licenciar, compartilhar acesso ou de outra forma explorar o conteúdo dos cursos sem autorização prévia por escrito da Educa Tecnologia.
    
    7. LEI APLICÁVEL E JURISDIÇÃO
    7.1. Este Contrato será regido e interpretado de acordo com as leis vigentes no Brasil.
    7.2. Quaisquer disputas decorrentes deste Contrato serão de competência exclusiva dos tribunais da jurisdição da sede da Educa Tecnologia.
    
    Ao assinar os cursos da Plataforma EducaFlix, o Assinante concorda com os termos deste Contrato e se compromete a cumprir suas disposições. Este Contrato entra em vigor na data da assinatura dos cursos.
    `,
    url:
      "",
  },
  {
    tipo: "uso",
    text:
    `
    TERMOS DE USO DA PLATAFORMA EDUCAFLIX
    
    Estes Termos de Uso ("Termos") regulam o uso da plataforma EducaFlix ("Plataforma"), desenvolvida e operada pela Empresa Educa Tecnologia LTDA ("Educa Tecnologia", "nós" ou "nosso"), um hub de conteúdos educacionais digitais. Ao acessar e utilizar a Plataforma EducaFlix, você ("Usuário" ou "você") concorda em cumprir estes Termos. Por favor, leia atentamente os seguintes termos antes de usar nossos serviços.
    
    1. ACEITAÇÃO DOS TERMOS DE USO
    
    Ao acessar ou usar a Plataforma EducaFlix, você concorda com estes Termos de Uso e com a nossa Política de Privacidade, que faz parte integrante deste contrato. Se você não concordar com qualquer parte destes Termos, não está autorizado a usar nossa Plataforma.
    
    2. DESCRIÇÃO DOS SERVIÇOS
    
    A Plataforma EducaFlix é um hub de conteúdos educacionais digitais que oferece uma variedade de serviços, incluindo acesso a vídeos educacionais, apostilas, simulados e estatísticas de desempenho de estudo. Os serviços podem ser assinados separadamente, de forma semelhante às vendas do iFood, permitindo que o Usuário personalize sua experiência de aprendizado. Os serviços podem ser acessados por meio de aplicativos móveis, web ou smart TVs, todos vinculados a uma única conta do Usuário.
    
    3. CONTA DE USUÁRIO
    
    3.1. Para utilizar a Plataforma EducaFlix, é necessário criar uma conta de usuário. Você é responsável por manter a confidencialidade de suas informações de login e pela segurança da sua conta.
    
    3.2. Você concorda em fornecer informações precisas e atualizadas ao criar sua conta e manter essas informações atualizadas durante o uso da Plataforma.
    
    4. ASSINATURAS E PAGAMENTOS
    
    4.1. Alguns serviços da Plataforma EducaFlix podem ser oferecidos mediante pagamento de uma taxa de assinatura. Os detalhes sobre preços, planos e formas de pagamento serão especificados na seção de assinaturas da Plataforma.
    
    4.2. O pagamento pelas assinaturas é de responsabilidade do Usuário e será processado de acordo com os termos e condições estabelecidos no momento da assinatura.
    
    4.3. A Educa Tecnologia reserva-se o direito de alterar os preços e os planos de assinatura a qualquer momento, com aviso prévio adequado aos Usuários.
    
    5. PROPRIEDADE INTELECTUAL
    
    5.1. A Plataforma EducaFlix e todo o seu conteúdo, incluindo vídeos, apostilas, simulados e materiais relacionados, são de propriedade exclusiva da Educa Tecnologia ou de seus licenciadores e estão protegidos pelas leis de direitos autorais e outras leis de propriedade intelectual.
    
    5.2. O Usuário concorda em não copiar, distribuir, transmitir, exibir, vender, licenciar ou de outra forma explorar qualquer conteúdo da Plataforma EducaFlix sem autorização prévia por escrito da Educa Tecnologia.
    
    6. RESPONSABILIDADE DO USUÁRIO
    
    6.1. O Usuário concorda em usar a Plataforma EducaFlix de forma responsável e ética, não violando estes Termos ou qualquer lei aplicável.
    
    6.2. O Usuário é responsável por todo o conteúdo que postar, compartilhar ou enviar por meio da Plataforma EducaFlix.
    
    7. RESCISÃO
    
    7.1. A Educa Tecnologia reserva-se o direito de rescindir ou suspender a conta de qualquer Usuário que viole estes Termos de Uso ou que esteja envolvido em atividades fraudulentas ou prejudiciais à Plataforma.
    
    8. LIMITAÇÃO DE RESPONSABILIDADE
    
    8.1. A Plataforma EducaFlix é fornecida "no estado em que se encontra" e "conforme disponível", sem garantias expressas ou implícitas de qualquer tipo. A Educa Tecnologia não faz declarações ou garantias quanto à qualidade, precisão, confiabilidade ou adequação dos serviços oferecidos.
    
    8.2. A Educa Tecnologia não se responsabiliza por quaisquer interrupções, erros, atrasos ou falhas na Plataforma EducaFlix, nem por quaisquer danos diretos, indiretos, incidentais, consequentes ou especiais que possam surgir como resultado do uso ou da incapacidade de usar a Plataforma.
    
    9. FORÇA MAIOR
    
    A Educa Tecnologia não será responsável por qualquer falha ou atraso no desempenho das suas obrigações nos termos destes Termos de Uso devido a eventos fora do seu controle razoável, incluindo, mas não se limitando a, greves, desastres naturais, atos de guerra, atos de terrorismo, problemas técnicos ou outros eventos imprevisíveis.
    
    10. DISPOSIÇÕES FINAIS
    
    10.1. Estes Termos de Uso constituem o acordo completo entre o Usuário e a Educa Tecnologia em relação ao uso da Plataforma EducaFlix.
    
    10.2. A Educa Tecnologia pode atualizar ou modificar estes Termos de Uso periodicamente, e as alterações serão efetivas a partir da data de sua publicação na Plataforma.
    
    10.3. Estes Termos serão regidos e interpretados de acordo com as leis vigentes no Brasil.
    
    Ao continuar a usar a Plataforma EducaFlix, você confirma sua compreensão e aceitação destes Termos de Uso. Se tiver alguma dúvida ou preocupação, entre em contato conosco através dos canais de suporte disponíveis na Plataforma.
    `,
    url:
      "",
  },
  {
    tipo: "privacidade",
    text:
    `
    POLÍTICA DE PRIVACIDADE DA PLATAFORMA EDUCAFLIX
    
    Esta Política de Privacidade descreve como a Empresa Educa Tecnologia LTDA ("Educa Tecnologia", "nós" ou "nosso") coleta, utiliza, compartilha e protege suas informações pessoais ao utilizar a Plataforma EducaFlix ("Plataforma"). Ao acessar e utilizar nossos serviços, você ("Usuário" ou "você") concorda com as práticas descritas nesta Política de Privacidade.
    
    1. INFORMAÇÕES COLETADAS
    
    1.1. Informações de Conta: Para utilizar a Plataforma EducaFlix, você deve criar uma conta de usuário. Nesse processo, coletamos informações pessoais, como nome, endereço de e-mail, senha e outras informações de perfil.
    
    1.2. Informações de Pagamento: Se você optar por assinar nossos serviços pagos, poderemos coletar informações de pagamento, como números de cartão de crédito, endereço de cobrança e outras informações financeiras necessárias para processar seu pagamento.
    
    1.3. Informações de Uso: Coletamos informações sobre como você utiliza a Plataforma, incluindo quais serviços acessa, conteúdo visualizado, interações com outros usuários e dados de desempenho de estudo.
    
    1.4. Informações de Dispositivo: Podemos coletar informações sobre o dispositivo que você usa para acessar a Plataforma, incluindo o tipo de dispositivo, sistema operacional, informações de rede e identificadores exclusivos do dispositivo.
    
    2. USO DAS INFORMAÇÕES
    
    2.1. Utilizamos as informações coletadas para os seguintes fins:
    
    a. Fornecer e melhorar os serviços da Plataforma EducaFlix.
    b. Personalizar sua experiência de aprendizado.
    c. Processar transações de pagamento, quando aplicável.
    d. Comunicar-se com você, incluindo o envio de atualizações e informações relevantes sobre a Plataforma.
    e. Monitorar e analisar o uso da Plataforma para melhorar nossos serviços.
    
    3. COMPARTILHAMENTO DE INFORMAÇÕES
    
    3.1. Não compartilhamos suas informações pessoais com terceiros, exceto nas seguintes situações:
    
    a. Com fornecedores de serviços terceirizados que nos auxiliam na prestação dos serviços da Plataforma, sujeitos a obrigações de confidencialidade.
    b. Quando necessário para cumprir com obrigações legais, regulatórias ou judiciais.
    c. Em caso de fusão, aquisição, venda de ativos ou mudança de controle da Educa Tecnologia, suas informações podem ser transferidas para a parte adquirente, sujeitas a obrigações de privacidade.
    
    4. SEGURANÇA DAS INFORMAÇÕES
    
    4.1. Implementamos medidas de segurança razoáveis para proteger suas informações pessoais contra acesso não autorizado, uso indevido ou divulgação.
    
    4.2. No entanto, nenhum sistema de segurança é completamente imune a riscos. A Educa Tecnologia não pode garantir a segurança absoluta das informações pessoais do Usuário.
    
    5. DIREITOS DO USUÁRIO
    
    5.1. Você tem direito a:
    
    a. Acessar e revisar suas informações pessoais.
    b. Corrigir informações imprecisas ou desatualizadas.
    c. Excluir suas informações pessoais, sujeitas a limitações legais.
    d. Optar por não receber comunicações de marketing.
    
    6. ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE
    
    6.1. A Educa Tecnologia pode atualizar esta Política de Privacidade periodicamente. As alterações serão publicadas na Plataforma e entrarão em vigor na data da publicação.
    
    7. CONTATO
    
    7.1. Se tiver dúvidas ou preocupações sobre esta Política de Privacidade ou sobre como suas informações pessoais são tratadas, entre em contato conosco através dos canais de suporte disponíveis na Plataforma.
    
    Ao continuar a utilizar a Plataforma EducaFlix, você concorda com as práticas de coleta e uso de informações descritas nesta Política de Privacidade.
    `,
    url:
      "",
  },
];
