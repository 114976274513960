import React from "react";
import CollectionsCard from "./../CollectionsCard";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Collections.css";
const Collections = () => {
  const cards = [
    {
      brand: "educaflix",
      image:
        "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fcards%2Fparceiros%2Flogoeducaflix.png?alt=media&token=445fa9e4-bbd7-4e0c-af75-5a1e69decd72&_gl=1*1d42a6l*_ga*ODIyOTEzNTM5LjE2OTU5NDA2Mzc.*_ga_CW55HF8NVT*MTY5NTk0MDYzNy4xLjEuMTY5NTk0NDg2MC42MC4wLjA.",
      video:
        "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fcards%2Fparceiros%2Fvideos%2F01.mp4?alt=media&token=a2855884-93b5-42c6-a968-1c9ccab3fb80&_gl=1*wkztrm*_ga*NjMzNTUwOTI5LjE2OTU4NzAwNDc.*_ga_CW55HF8NVT*MTY5NjU2MzU5MC45LjEuMTY5NjU2MzkzMS42MC4wLjA.",
    },
    {
      brand: "faspm",
      image:
        "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fcards%2Fparceiros%2Flogofaspm.png?alt=media&token=0508ab69-4f71-40d5-9a5d-1f85f21b86b6&_gl=1*6idcgn*_ga*ODIyOTEzNTM5LjE2OTU5NDA2Mzc.*_ga_CW55HF8NVT*MTY5NTk0MDYzNy4xLjEuMTY5NTk0NDkwMC4yMC4wLjA.",
      video:
        "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fcards%2Fparceiros%2Fvideos%2F04.mp4?alt=media&token=a2855884-93b5-42c6-a968-1c9ccab3fb80&_gl=1*wkztrm*_ga*NjMzNTUwOTI5LjE2OTU4NzAwNDc.*_ga_CW55HF8NVT*MTY5NjU2MzU5MC45LjEuMTY5NjU2MzkzMS42MC4wLjA.",
    },
    {
      brand: "ingrid",
      image:
        "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fcards%2Fparceiros%2Flogoingridconcursos.png?alt=media&token=805228a4-4881-4687-aefe-206547d66f9a&_gl=1*3cdcut*_ga*ODIyOTEzNTM5LjE2OTU5NDA2Mzc.*_ga_CW55HF8NVT*MTY5NTk0MDYzNy4xLjEuMTY5NTk0NDkyNy42MC4wLjA.",
      video:
        "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fcards%2Fparceiros%2Fvideos%2F03.mp4?alt=media&token=a2855884-93b5-42c6-a968-1c9ccab3fb80&_gl=1*wkztrm*_ga*NjMzNTUwOTI5LjE2OTU4NzAwNDc.*_ga_CW55HF8NVT*MTY5NjU2MzU5MC45LjEuMTY5NjU2MzkzMS42MC4wLjA.",
    },
    {
      brand: "acai-valley",
      image:
        "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fcards%2Fparceiros%2Flogoav.png?alt=media&token=91a4a030-7a83-4070-93a3-1852549e7e9b&_gl=1*my8hs9*_ga*ODIyOTEzNTM5LjE2OTU5NDA2Mzc.*_ga_CW55HF8NVT*MTY5NTk0MDYzNy4xLjEuMTY5NTk0NDk3MS4xNi4wLjA.",
      video:
        "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fcards%2Fparceiros%2Fvideos%2F02.mp4?alt=media&token=a2855884-93b5-42c6-a968-1c9ccab3fb80&_gl=1*wkztrm*_ga*NjMzNTUwOTI5LjE2OTU4NzAwNDc.*_ga_CW55HF8NVT*MTY5NjU2MzU5MC45LjEuMTY5NjU2MzkzMS42MC4wLjA.",
    },
    {
      brand: "ondrone",
      image:
        "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fcards%2Fparceiros%2Flogoondrone.png?alt=media&token=b61e546b-849c-4933-ae6d-191223dc88e6&_gl=1*8tlw1d*_ga*ODIyOTEzNTM5LjE2OTU5NDA2Mzc.*_ga_CW55HF8NVT*MTY5NTk0MDYzNy4xLjEuMTY5NTk0NTAxNS42MC4wLjA.",
      video:
        "https://vod-bgc-sa-east-1.media.dssott.com/bgui/ps01/disney/bgui/2019/08/01/1564676296-national-geographic.mp4",
    },
  ];
  const config = {
    slidesToShow: 2,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 768,
      },
      {
        breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
        settings: "unslick",
      },
    ],
  };
  return (
    <section className="collections">
      <Slider {...config}>
        {cards.map((card) => (
          <Link key={card.brand} to={`/brand/${card.brand}`}>
            <CollectionsCard
              key={card.brand}
              brand={card.brand}
              video={card.video}
              image={card.image}
            />
          </Link>
        ))}
      </Slider>
    </section>
  );
};

export default Collections;
