import React, { useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useAuth } from "../../providers/auth";
// import SingleRow from "../SingleRow";
import SingleRow from "../SingleRowCards";
import requests from "../../requests";
import "./BrandPage.css";

const BrandPage = (props) => {
  const { user } = useAuth();
  const history = useHistory();
  const brand = props.match.params.brand;
  const isAuthenticated = !!user.token;

  useEffect(() => {
    window.scrollTo(0, 0);
    
    if (!isAuthenticated) {
      history.push('/login');
    }
  }, []);

  return (
    <main className="brandPage">
      <div className="brandPage__background">
        <img alt="" src={`/images/brands/${brand}-bg.jpg`} />
      </div>
      <div className="brandPage__image">
        {brand !== "pixar" ? (
          <img src={`/images/brands/${brand}.png`} alt="" />
        ) : null}
      </div>
      <div className="brandPage__movies movieRows__container">
        {brand === "marvel" ? (
          <>
            <SingleRow
              title="Marvel Universe"
              fetchUrl={requests.fetchMarvelPhases}
            />
            <SingleRow
              title="Marvel Universe: Phase One"
              fetchUrl={requests.fetchMarvelPhaseOne}
            />
            <SingleRow
              title="Marvel Universe: Phase Two"
              fetchUrl={requests.fetchMarvelPhaseTwo}
            />
            <SingleRow
              title="Marvel Universe: Phase Three"
              fetchUrl={requests.fetchMarvelPhaseThree}
            />
          </>
        ) : null}
        {brand === "pixar" ? (
          <>
            <SingleRow
              title="Pixar Movies"
              fetchUrl={requests.fetchPixar}
            ></SingleRow>
            <SingleRow
              title="Toy Story Collection"
              fetchUrl={requests.fetchToyStory}
            ></SingleRow>
            <SingleRow
              title="Cars Collection"
              fetchUrl={requests.fetchCars}
            ></SingleRow>
            <SingleRow
              title="Unexpected Heroes"
              fetchUrl={requests.fetchUnexpectedHeroes}
            ></SingleRow>
          </>
        ) : null}
        {brand === "star-wars" ? (
          <>
            <SingleRow
              title="Star Wars Collection"
              fetchUrl={requests.fetchStarWars}
            ></SingleRow>
            <SingleRow
              title="Star Wars Lego"
              fetchUrl={requests.fetchStarWarsLego}
            ></SingleRow>
          </>
        ) : null}
        {brand === "faspm" ? (
          <>
            <SingleRow
              title="CFP - Curso de Formação de Praças"
              fetchUrl={requests.fetchFaspmCfp + user.token}
            ></SingleRow>
            {/* <SingleRow
              title="CFO - Curso de Formação de Oficiais"
              fetchUrl={requests.fetchFaspmCfp+user.token}
            ></SingleRow> */}
            {/* <SingleRow
              title="Series"
              fetchUrl={requests.fetchDisneySeries}
            ></SingleRow> */}
          </>
        ) : null}
        {brand === "national-geographic" ? (
          <>
            <SingleRow
              title="Movies"
              fetchUrl={requests.fetchNationalMovies}
            ></SingleRow>
          </>
        ) : null}
      </div>
    </main>
  );
};

export default withRouter(BrandPage);
